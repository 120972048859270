<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="vertical">
          <a-row :gutter="48">
            <a-col
              :md="6"
              :sm="24"
            >
              <a-form-item
                label="消息标题	"
                prop="title"
              >
                <a-input
                  v-model="queryParam.newsTitle"
                  placeholder="请输入"
                  allow-clear
                />
              </a-form-item>
            </a-col>
            <a-col
              :md="!advanced && 8 || 24"
              :sm="24"
            >
              <span
                class="table-page-search-submitButtons"
                :style="advanced && { float: 'right', overflow: 'hidden' } || {}"
              >
                <a-button
                  type="primary"
                  @click="handleQuery"
                ><a-icon type="search" />查询</a-button>
                <a-button
                  style="margin-left: 8px;margin-top: 28px;"
                  @click="resetQuery"
                ><a-icon type="redo" />重置</a-button>
                <a-button
                  type="primary"
                  @click="$refs.createForm.handleAdd()"
                  v-hasPermi="['com:news:add']"
                  style="margin-left: 8px;"
                >
                  <a-icon type="plus" />新增
                </a-button>
                <!-- <a
                  @click="toggleAdvanced"
                  style="margin-left: 8px"
                >
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a> -->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <!-- <div class="table-operations">
        <a-button
          type="primary"
          @click="$refs.createForm.handleAdd()"
          v-hasPermi="['com:news:add']"
        >
          <a-icon type="plus" />新增
        </a-button>
        <a-button
          type="primary"
          :disabled="single"
          @click="$refs.createForm.handleUpdate(undefined, ids)"
          v-hasPermi="['tl:device:edit']"
        >
          <a-icon type="edit" />修改
        </a-button>
        <a-button
          type="danger"
          :disabled="multiple"
          @click="handleDelete"
          v-hasPermi="['tl:device:remove']"
        >
          <a-icon type="delete" />删除
        </a-button>

        <table-setting
          :style="{ float: 'right' }"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList"
        />
      </div> -->
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <CreateInfo
        ref="createForms"
        @ok="getList"
      />
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <div
          slot="fileUrl"
          slot-scope="text, record"
        >
          <div v-viewer>
            <img
              style="width:50px;height;:50px"
              :src="record.fileUrl"
            />
          </div>

        </div>
        <!-- <span
          slot="produceDate"
          slot-scope="text, record"
        >
          {{ parseTime(record.produceDate, '{y}-{m}-{d}') }}
        </span> -->
        <span
          slot="createTime"
          slot-scope="text, record"
        >
          {{ parseTime(record.createTime) }}
        </span>
        <span
          slot="newsContent"
          slot-scope="text, record"
        >
          <a @click="$refs.createForms.handleUpdate(record, undefined)">
            查看
          </a>
        </span>
        <span
          slot="operation"
          slot-scope="text, record"
        >
          <a
            @click="$refs.createForm.handleUpdate(record, undefined)"
            v-hasPermi="['com:news:edit']"
          >
            <a-icon type="edit" />修改
          </a>
          <a-divider
            type="vertical"
            v-hasPermi="['com:Message:remove']"
          />
          <a
            @click="handleDelete(record)"
            v-hasPermi="['com:news:remove']"
          >
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageMessage, delMessage } from '@/api/project/message'
import CreateForm from './modules/CreateForm'
import CreateInfo from './modules/info'
import { tableMixin } from '@/store/table-mixin'
export default {
  name: 'Device',
  components: {
    CreateForm,
    CreateInfo
  },
  mixins: [tableMixin],
  data () {
    return {
      deviceTypeList: [],
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        corporateName: undefined,
        code: undefined,
        fullName: undefined,
        serialNumber: undefined,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '消息标题',
          dataIndex: 'newsTitle',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '图片',
          dataIndex: 'fileUrl',
          scopedSlots: { customRender: 'fileUrl' },
          width: 100,
          ellipsis: true,
          align: 'center'
        },
        {
          title: '消息内容',
          dataIndex: 'newsContent',
          scopedSlots: { customRender: 'newsContent' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '消息简述',
          dataIndex: 'newsSketch',
          ellipsis: true,
          align: 'center'
        },

        {
          title: '添加时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          width: 150,
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 280,
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询设备信息列表 */
    getList () {
      this.loading = true
      pageMessage(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        sortField: 'createTime',
        sortOrder: 'DESCEND',
        corporateName: undefined,
        code: undefined,
        fullName: undefined,
        serialNumber: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        onOk () {
          const data = []
          that.ids.map(id => data.push({ 'id': id }))
          return delMessage(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel () { }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('valve/device/export', {
            ...that.queryParam
          }, `设备信息_${new Date().getTime()}.xlsx`)
        },
        onCancel () { }
      })
    }
  }
}
</script>
@/api/valve/deviceType
